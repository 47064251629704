.leading {
    font-size: rem(22);
}

a {
	&[target="_blank"]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".docx"]):not([href$=".xsd"]):not([href$=".xml"]):not([href$=".zip"]):not([href$=".xls"]):not([href$=".xlsx"]):not(.btn):not(.no-icon) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f35d);
            font-size: 0.7rem;
		}
	}

	&[href$=".pdf"]:not(.btn):not(.card) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f1c1, 500);
            font-size: 1rem;
		}
	}

	&[href$=".zip"]:not(.btn):not(.card) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f1c6, 500);
            font-size: 1rem;
		}
	}

	&[href$=".xsd"]:not(.btn):not(.card), &[href$=".xml"]:not(.btn):not(.card), &[href$=".xls"]:not(.btn):not(.card), &[href$=".xlsx"]:not(.btn):not(.card) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f1c3, 500);
            font-size: 1rem;
		}
	}

	&[href$=".docx"]:not(.btn):not(.card), &[href$=".doc"]:not(.btn):not(.card) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f1c2, 500);
            font-size: 1rem;
		}
	}
}

.upper-title {
	font-size: 1rem !important;
	font-weight: normal !important;
	text-transform: uppercase !important;
	color: $gray-500 !important;
	margin-top: 3rem !important;
	margin-bottom: 0 !important;
}

// h1, .h1 {
// 	font-size: rem(32);
// 	font-weight: 500;
// }

// h2, .h2 {
// 	font-size: rem(24);
// 	font-weight: 400;
// }

// h3, .h3 {
// 	font-size: rem(16);
// 	font-weight: 400;
// }

// h4, .h4 {
// 	font-size: rem(16);
// 	font-weight: 400;
// }

// .content {
// 	h2, .h2 {
// 		color: $secondary;
// 	}

// 	h3, .h3 {
// 		color: $primary;
// 	}

// 	ul, p, a, table {
// 		font-size: rem(14);
// 	}

// 	table th {
// 		background-color: $secondary;
// 		color: white;
// 		border-color: $secondary;
// 		font-weight: 500;
// 	}
// }

// strong, b {
// 	font-weight: 500;
// }