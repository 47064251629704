.search-bar {
    .form-control {
        border: 0;

        @include media-breakpoint-up(xl) {
            &::placeholder {
                text-align: right;
            }
    
            &:focus {
                &::placeholder {
                    text-align: left;
                }
            }
        }
    }

    .fa {
        font-size: 1.2rem;
        color: $primary;
    }

    &.search-bar-mobile {
        .form-control {
            padding: 0.5rem 1rem;
            height: calc(1.5em + 2.75rem - 2px);
        }
    }
}

.search-group {
    .form-control {
        border-right: 0;
    }

    .btn {
        background: white;
        border: 1px solid $purple-light;
        color: $primary;
        border-left: 0;

        .fa {
            font-size: 1.25rem;
            vertical-align: middle;
        }
    }
}

.search-filters {
    .navbar-toggler {
        width: auto;
        height: auto;
    }
}