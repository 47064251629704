.square-wrap {
    @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: 320px;
    }
}

.square {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    display: block;
    text-decoration: none !important;

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        padding-left: 10px;
        padding-right: 10px;

        font-size: rem(18);

        .square-text {
            width: 100%;
        }
    }

    @include media-breakpoint-up(lg) {
        .square-icon + .square-text {
            min-height: calc(2 * calc(1em * 1.5)); // expect 2 lines, nbrLines * ( font-size * line-height )
            max-width: 100%; // IE11 fix
        }
    }

    .square-icon {
        img {
            width: 48px;
            height: 48px;

            @include media-breakpoint-up(md) {
                width: 56px;
                height: 56px;
            }
    
            @include media-breakpoint-up(xl) {
                width: 90px;
                height: 90px;
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: $white;
        box-shadow: 0px 0px 20px 0px rgba(198, 195, 212, 0.7);
        transition: $transition-base;
    }

    &:after {
        content: none !important;
    }

    &.square-primary {
        color: $white;
        background-color: $purple-light;

        &:before {
            background-color: $purple-light;
        }
    }

    &.square-outline {
        border: 1px solid $gray-200;
    }

    &.square-sm {
        .content {
            font-size: rem(16);
        }
    }

    &:hover {
        background-color: $purple;
        &:before {
            top: -5px;
            left: -5px;
            right: -5px;
            bottom: -5px;
        }

        &.square-primary {
            &:before {
                background-color: $purple;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .square {
        padding: 0.75rem;

        .content {
            position: relative;
            top: auto;
            left: auto;
            transform: none;
            flex-direction: row;
            justify-content: flex-start;
            padding: 0;
            text-align: left;
        }
    }
}