.search-results {
    .search-filters {
        .search-filters-title {
            text-transform: uppercase;
            font-weight: bold;
        }
        .dropdown-divider {
            margin: 0 1.5rem;
            @include media-breakpoint-down(md) {
                margin: 0 .75rem;
            }

            &:last-child {
                margin-top: 0;
                border: none;
            }
            &.subgroup-divider {
                margin-top: .5rem;
                margin-bottom: .5rem;
            }
        }

        .dropdown-item.checkbox.disabled {
            .badge {
                background-color: $gray-500;
            }
        }

        @include media-breakpoint-up(lg) {
            .dropdown-menu {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
        }
        @include media-breakpoint-down(md) {
            .nav-fill {
                width: 100%;
                padding-top: .5rem;
                .nav-item {
                    text-align: left;
                }
            }
            .dropdown-menu {
                box-shadow: none;
                margin-bottom: .5rem;
                font-size: rem(14);
                a.dropdown-item {
                    padding: .75rem;
                }
                .dropdown-item {
                    &.dropdown-item-subgroup {
                        padding: 0;
                        a {
                            padding: .75rem;
                        }
                    }
                    &.checkbox {
                        padding: .5rem .75rem;
                    }
                }
            }
        }

        @include media-breakpoint-down(xs) {
            .dropdown-menu {
                .dropdown-item {
                    white-space: initial;
                }
            }
        }
    }
    
    .search-results-title {
        font-size: rem(30);
        font-weight: normal;
    }
    .custom-select {
        color: $dark;
        font-size: rem(14);
    }

    .search-results-header {
        border-bottom: 1px solid $gray-300;
        min-height: 60px;
    }

    @include media-breakpoint-up(md) {
        .search-results-filter {
            flex: 0 0 268px;
            border-left: 1px solid $gray-300;
            padding-left: 10px;
        }
    }
}