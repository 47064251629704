hr.sm {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.icon-arrow-bottom {
    transform: rotate(90deg);
}

.bg-gray {
    background-color: $gray-500 !important;
}