body.test {
    background: #fff url("../img/bg/test-content-bg.png") repeat 0 0;

    .navbar-light {
        background-color: transparent !important;
    }
}
  
body.accept {
    background: #fff url("../img/bg/accept-content-bg.png") repeat 0 0;

    .navbar-light {
        background-color: transparent !important;
    }
}

// #main-content {
//     @include media-breakpoint-down(md) {
//         padding-top: 79px;
//     }
// }

#form-custom {
    form {
        margin-top: 2rem;

        .submit {
            margin-top: 1.5rem;
            margin-bottom: 2rem;
        }

        .file-list {
            margin-top: 1rem;
        }
    }
}

.v-align-middle {
    display: flex;
    align-items: center;
    
    // > *:last-child {
    //     margin-bottom: 0;
    // }
}