.btn {
    text-decoration: none;

    &:focus, &:active {
        outline: 0 !important;
        box-shadow: none !important;
    }

    &.btn-primary:hover, &.btn-primary:focus, &.btn-primary:active {
        background-color: $purple-light;
        border-color: $purple-light;
    }

    &.btn-link {
        text-decoration: none !important;
        display: inline-block;
        border-bottom: 1px solid;
        border-radius: 0;
        padding: 0;
    }

    &.btn-outline-white {
        border-color: white;
        color: white;

        &:hover, &:focus, &:active {
            background-color: white;
            color: $primary;
        }
    }
}