.custom-select {
    border: 0;
    -webkit-appearance: none;
}

.custom-control-input:checked + label {
    font-weight: bold;
}

label.required:after {
    content: '*';
    margin-left: 0.5rem;
    color: red;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
    border-color: $custom-control-label-disabled-color;
}