.accordion, .collapse-box {
    .collapse-wrapper {
        padding-left: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid $border-color;

        .collapse > *:last-child {
            margin-bottom: 3rem;
        }
    }

    [data-toggle="collapse"] {
        position: relative;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        text-decoration: none;
        padding: 1rem;

        > * {
            font-size: rem(20) !important;
            margin-bottom: 0 !important;
            font-weight: normal !important;
            margin-top: 0 !important;
        }

        &.collapse-primary {
            background-color: $purple-xtra-xtra-light;

            > * {
                color: $primary !important;
            }

            &[aria-expanded="true"] {
                background-color: $primary;
                color: white;

                &:before {
                    background-color: white;
                    color: $primary;
                }

                > * {
                    color: white !important;
                }
            }
            &[aria-expanded="false"] {
                &:before {
                    background-color: $primary;
                    color: white;
                }
            }
        }

        &.collapse-secondary {
            background-color: #edf1d7;

            > * {
                color: $dark !important;
            }

            &[aria-expanded="true"] {
                background-color: $secondary;
                color: white;

                &:before {
                    background-color: white;
                    color: $secondary;
                }

                > * {
                    color: white !important;
                }
            }
            &[aria-expanded="false"] {
                &:before {
                    background-color: $secondary;
                    color: white;
                }
            }
        }

        &:before {
            display: inline-block;
            width: 1.5rem;
            text-align: center;
            margin-right: 1rem;
            font-size: 0.75rem;
            height: 1.5rem;
            line-height: 1.5rem;
            flex: 0 0 1.5rem;
            margin-top: 1px;
        }

        &[aria-expanded="true"] {
            &:before {
                @include fonticon(\f068);
            }
        }
        &[aria-expanded="false"] {
            &:before {
                @include fonticon(\f067);
            }
        }
    }

    h3, .h3 {
        font-size: rem(18) !important;
        font-weight: bold !important;
        text-transform: none !important;
        color: inherit !important;
    }

    h4, .h4,
    h5, .h5 {
        font-size: 1rem;
    }
}

#privacy-details {
    h3, .h3 {
        font-weight: bold !important;
        text-transform: none !important;
        color: inherit !important;
    }

    h4, .h4,
    h5, .h5 {
        font-size: 1rem;
    }

    h5, .h5 {
        color: $primary;
    }

    > [data-toggle="collapse"] {
        padding: 1rem 0;
        position: relative;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        text-decoration: none;

        > * {
            color: $secondary;
            font-weight: 700 !important;
            font-size: 1.5rem !important;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
        }

        &:before {
            content: '';
            display: none;
        }

        &:after {
            @include fonticon(\f078);
            display: inline-block;
            width: 1.5rem;
            text-align: center;
            margin-left: auto;
            font-size: 0.75rem;
            height: 1.5rem;
            line-height: 1.5rem;
            flex: 0 0 1.5rem;
            margin-top: 1px;
            font-size: 1.2rem;
        }

        &[aria-expanded="true"] {
            &:after {
                @include fonticon(\f077);
            }
        }
    }

    .multi-collapse {
        .collapse-list {
            position: relative;
            text-align: left;
            padding-left: 1.2rem;
            color: $body-color;
            text-decoration: none;
            font-weight: 500;
            margin-top: 1rem;

            span {
                text-decoration: underline;
                font-weight: normal;
            }

            &:before {
                content: '';
                width: 6px;
                height: 6px;
                position: absolute;
                left: 0;
                top: calc(0.5em * 1.5 - 3px);
                background: $green;
            }

            &[aria-expanded="false"] {
                span {
                    display: none;
                    
                    &:last-child {
                        display: inline-block;
                    }
                }
            }
            &[aria-expanded="true"] {
                span {
                    display: inline-block;
                    
                    &:last-child {
                        display: none;
                    }
                }
            }
        }

        .collapse-content {
            padding: 1rem 0 1rem 1.2rem;
        }
    }
}

.collapse-all,
.expand-all {
    &:after {
        display: inline-block;
        text-align: center;
        margin-left: 0.5rem;
        width: 1rem;
    }
}
.collapse-all {
    &:after {
        content: '-';
    }
}
.expand-all {
    &:after {
        content: '+';
    }
}