.page-themes {
    background-color: $light;
    
    @include media-breakpoint-up(md) {
        #page-content {
            background-image: linear-gradient(184deg, $light calc(100% - 220px), white calc(100% - 218px));
        }
    }

    @include media-breakpoint-up(lg) {
        #page-content {
            background-image: linear-gradient(184deg, $light calc(100% - 300px), white calc(100% - 298px));
        }
    }
}