header {
    background-color: $white;
    position: relative;
    width: 100%;
    border-top: 4px solid $purple-light;

    // @include media-breakpoint-down(md) {
    //     position: fixed;
    //     z-index: $zindex-fixed;
    //     top: 0;
    // }

    &.sticky {
        position: fixed;
        display: none;
        z-index: #{$zindex-fixed + 1};
    }

    .navbar-brand {
        color: $gray-500 !important;
        font-size: 15px;
        font-weight: 500;
    }

    .nav-link {
        text-transform: uppercase;
        font-size: rem(15);
    }

    .dropdown-menu {
        border: 0;
        margin: 0;
        @include media-breakpoint-up(xl) {
            margin: 0 0 0 -1px;
        }

        .dropdown-item.active {
            position: relative;
            background: none;
            font-weight: bold;

            @include media-breakpoint-up(xl) {
                a {
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        background: $secondary;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 5px;
                    }
                }
            }
        }

        .dropdown-item:active, .dropdown-item:hover {
            background: $purple-xtra-xtra-light !important;
        }

        .dropdown-link {
            color: $purple;
            display: block;
        }
    }

    .dropdown-toggle {
        cursor: pointer;
    }
}

#main-nav {
    .nav-item, & > .nav-link {
        position: relative;

        @include media-breakpoint-up(xl) {
            border-left: 1px solid $gray-300;
    
            &:last-child {
                border-right: 1px solid $gray-300;
            }
        }

        @include media-breakpoint-down(lg) {
            border-top: 1px solid $gray-300;
    
            &:last-child {
                border-bottom: 1px solid $gray-300;
            }
        }

        &.active {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                background: $secondary;
                bottom: 0;
                left: 0;
                top: 0;
                width: 5px;
                @include media-breakpoint-up(xl) {
                    background: $secondary;
                    right: 0;
                    top: auto;
                    width: auto;
                    height: 5px;
                }
            }
        }
    }

    .nav-link {
        padding: 1.35rem 1rem;
    }

    @include media-breakpoint-up(xl) {
        .dropdown-menu {
            border-top: 2px solid $gray-300;
        }
    }
    @include media-breakpoint-down(lg) {
        .dropdown-menu {
            box-shadow: none;
            background-color: $purple-xtra-xtra-light;
        }
    }

    .dropdown-item {
        padding: 0;
        > a {
            padding: .75rem 1.5rem;
            @include media-breakpoint-up(xl) {
                padding: 0.6rem 1.5rem;
            }
        }
    }
}

.navbar {
    .container {
        &:first-child {
            padding: $spacer/2 15px;
        }

        @include media-breakpoint-down(lg) {
            max-width: none;
        }
    }

    .navbar-nav {
        a {
            text-decoration: none !important;
        }
    }
}

.useful-links {
    .nav-link {
        font-size: rem(14) !important;
    }
    
    .dropdown-menu {
        position: absolute;

        .dropdown-item:active {
            background: $primary !important;
        }
    }
}