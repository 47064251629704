// @import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;500;700&display=swap');

@font-face {
    font-family: 'Libre Franklin';
    src: url('../fonts/LibreFranklin-Regular.ttf')  format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url('../fonts/LibreFranklin-Medium.ttf')  format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Libre Franklin';
    src: url('../fonts/LibreFranklin-Bold.ttf')  format('truetype');
    font-weight: 700;
}