#news {
    @include media-breakpoint-up(md) {
        [class*="col"] + [class*="col"] {
            border-left: 1px solid $gray-300;
        }
    }

    .card {
        border: 0;
    }

    .card-body {
        padding: 0 !important;
    }

    .card-title {
        font-size: rem(24);
        font-weight: 400;
        text-transform: none;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $purple;
            }
        }
    }

    .card-subtitle {
        font-size: rem(16);
        color: $gray-500;
    }
}