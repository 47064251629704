.page-home {
    section {
        padding-top: rem(75);
        padding-bottom: rem(75);
    }

    h2, .h2 {
        font-size: rem(16);
        font-weight: 400;
        text-transform: uppercase;
        color: $body-color;
    }

    #intro {
        padding-bottom: 0;
        background-color: $gray-150;

        h2, .h2 {
            font-size: rem(38);
            color: $body-color;
            letter-spacing: .25px;
            text-transform: none;
        }

        .lead, p {
            font-size: rem(22);
            line-height: 1.2;
            color: $gray-600;
            margin-top: rem(25);
        }

        & + .skew {
            margin-top: -50px;
        }
    }

    #themes {
        .h2 {
            color: $dark;
        }

        .themes-grid {
            [class*="col"] {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }

    #actions {
        .h3 {
            font-size: rem(24);
            font-weight: 400;
            line-height: rem(30);
            max-width: 100%;
        }
    }

    #newsletter {
        p {
            font-size: rem(30);
        }
    }

    footer {
        background: white;
        border-top: 0;
    }
}

.page-home-pro {
    #actions {
        padding-bottom: rem(75);

        .h2 {
            color: $dark;
        }
    }

    #premiere-aide {
        padding-top: rem(75);
        padding-bottom: rem(75);

        a, a:hover {
            color: $secondary;
            text-decoration: none;
            font-size: rem(22);
        }
    }
}