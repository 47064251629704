// IE10 and IE11   (Method 2)
// ---------------------------------
@media all and (-ms-high-contrast:none){
  .d-flex > * {
    max-width: 100%;
  }
}
// ---------------------------------

/*----MS Edge Browser CSS Start----*/
@supports (-ms-ime-align:auto) and (display:-ms-grid){
  ul.nav,
  ul.list-unstyled,
  ul.dropdown-menu {
    list-style: none;
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) !important;
    list-style-type: none;
  }
}
/*----MS Edge Browser CSS End----*/