.dropdown-toggle::after {
    @include fonticon(\f078);
    border: 0;
    vertical-align: initial;
    font-size: rem(12);
    margin-left: 5px;
}

.dropdown-menu {
    box-shadow: 0px 0px 20px 0px rgba(198, 195, 212, 0.3);
}