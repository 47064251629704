#page-content {
    padding-bottom: rem(100);

    .h1, h1 {
        font-size: rem(38);
    }

    .h2, h2 {
        font-size: rem(26);
        font-weight: bold;
        color: $secondary;
        margin-bottom: rem(25);
        margin-top: rem(50);
    }

    .h3, h3 {
        font-size: rem(22);
        font-weight: normal;
        color: $gray-500;
        text-transform: uppercase;
    }

    .h3, h3,
    .h4, h4 {
        margin-top: rem(35);
    }

    .lead {
        font-size: rem(20);
    }

    ul:not(.navbar-nav):not(.list-unstyled):not(.pagination):not(.file-list):not(.nav-pills) {
        display: inline-block;
    }
    
    ul:not(.navbar-nav):not(.list-unstyled):not(.pagination):not(.nav-pills) {
        padding-left: 1.2rem;
        list-style-type: none;

        li {
            position: relative;
            // display: flex;
            text-align: left;

            &:before {
                content: '';
                width: 6px;
                height: 6px;
                position: absolute;
                left: -1.2rem;
                top: calc(0.5em * 1.5 - 3px);
                background: $green;
            }
        }

        li + li {
            margin-top: 0.5rem;
        }

        ul {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            
            li {
                position: relative;
    
                &:before {
                    border-radius: 50%;
                }
            }
        }
    }

    .purple ul:not(.navbar-nav):not(.list-unstyled):not(.pagination) li:before {
        background-color: $primary;
    }

    @include media-breakpoint-up(md) {
        .padded {
            padding-left: rem(76);
        }
    }

    .step-title {
        font-size: rem(24);
        font-weight: bold;
    }

    .card-body {
        padding: 2.25rem;

        @include media-breakpoint-down(sm) {
            padding: 1rem;
        }
    }

    .page-img-cap {
        img {
            margin-left: auto;
            margin-right: auto;
        }
        .skew {
            margin-top: -24px;
        }
    }

    img {
        margin: 0 1rem;

        &[style*="float:left"] {
            margin-left: 0;
        }

        &[style*="float:right"] {
            margin-right: 0;
        }
    }
}

#nav-filter .nav-item.active {
    font-weight: 700;
}

#application ul {
    display: block !important;
}