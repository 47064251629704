.table {
    thead {
        background-color: $gray-500;
        color: white;

        th {
            border: 0;
            font-weight: 500 !important;

            > strong {
                font-weight: 500 !important;
            }
        }
    }

    &.table-bordered td {
        border-left: 0;
        border-right: 0;
    }

    caption {
        caption-side: top;
    }
}

.table-responsive > .table-bordered {
    border: 1px solid #d1d1d1;
}