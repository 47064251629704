.page-news {
    .page-body {
        h1, .h1 {
            font-size: rem(38);
            font-weight: bold;
            margin-bottom: 2rem;
        }
        h2, .h2 {
            font-size: rem(30);
            font-weight: bold;
            margin-top: 2rem;
            margin-bottom: 1.5rem;
        }
        h3, .h3 {
            font-size: rem(24);
            font-weight: normal;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
        }
        h4, .h4,
        h5, .h5 {
            font-size: rem(18);
            color: $gray-500;
            font-weight: normal;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
    }
}